import React from "react"
import PrivacyPolicyTemplate from "../../../components/PrivacyPolicyTemplate"

const PrivacyPolicy = () => {
  return (
    <PrivacyPolicyTemplate
      name="Retine Gallien"
      mail="manager@retinegallien.com"
    />
  )
}

export default PrivacyPolicy
